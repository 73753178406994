<template>
	<div class="page">
		<Tables ref="xTable" :tableName="tableName" :tableData="tableData" :totalPage="totalPage" :isLoading="isLoading"
			:searchFormData="searchFormData" :showRefresh="true" :showSearch="true" :showSeq="false" :showOutput="true" @getList="getList"
			@clearSearch="clearSearch">
			<el-form-item slot="search-item">
				<el-input size="small" v-model="searchFormData.phone" clearable placeholder="请输入手机号"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-input size="small" v-model="searchFormData.carno" clearable placeholder="请输入车牌"></el-input>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.cartype" style="width: 120px;" clearable placeholder="车辆性质">
					<el-option v-for="(item,index) in carTypeValue" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.getphone" style="width: 120px;" clearable placeholder="采集人">
					<el-option v-for="(item,index) in getuserlist" :label="item.getname" :value="item.getphone">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-select size="small" v-model="searchFormData.platform" style="width: 120px;" clearable placeholder="平台">
					<el-option v-for="(item,index) in platformValue" :label="item" :value="index">
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item slot="search-item">
				<el-date-picker v-model="searchFormData.daterange" style="width: 240px;" clearable type="daterange" format="yyyy-MM-dd"
					value-format="yyyy-MM-dd" start-placeholder="开始采集时间" end-placeholder="结束采集时间" size="small">
				</el-date-picker>
			</el-form-item>
			<!--表格内容-->
			<vxe-table-column slot="table-item" field="phone" title="手机号" />
			<vxe-table-column slot="table-item" field="carno" title="车牌号" />
			<vxe-table-column slot="table-item" field="cartypeText" title="车辆性质" />
			<vxe-table-column slot="table-item" field="iswchatText" title="是否加微信" />
			<vxe-table-column slot="table-item" field="isgroupText" title="是否入群" />
			<vxe-table-column slot="table-item" field="platformText" title="平台" />
			<vxe-table-column slot="table-item" field="getname" title="采集人" />
			<vxe-table-column slot="table-item" field="ctime" title="采集时间" />
		</Tables>
	</div>
</template>
<script>
	import Tables from '@/components/tables'
	export default {
		name: 'user-fans-other',
		components: {
			Tables
		},
		data() {
			return {
				// 表格信息
				tableName: '用户采集',
				isLoading: false,
				tableData: [],
				totalPage: 0,
				searchFormData: {},
				getuserlist:[], //采集人列表
				carTypeValue:{
					"1":"网约车",
					"2":"货拉拉",
					"3":"私家车"
				},
				platformValue:{
					"0":"未知",
					"1":"叮当",
					"2":"第三方"
				},
			}
		},
		mounted: function() {
			//获取采集人列表
			let params = {
				token: this.$store.state.user.token,
			}
			this.isLoading = true;
			this.$api.Fans.fansother.getuserlist(params).then((res)=>{
				this.getuserlist = res;
				this.isLoading = false;
			});
		},
		computed: {

		},
		methods: {
			// 表格列表
			async getList(currentPage, pageSize) {
				let params = {
					token: this.$store.state.user.token,
					page: currentPage,
					size: pageSize
				}
				this.isLoading = true;
				params = Object.assign(params, this.searchFormData);
				const res = await this.$api.Fans.fansother.list(params)
				this.tableData = res.list;
				for(let v of this.tableData){
					v.platformText = this.platformValue[v.platform];
					v.cartypeText = this.carTypeValue[v.cartype];
					v.iswchatText = v.iswchat?"是":"否";
					v.isgroupText = v.isgroup?"是":"否";
				}
				this.totalPage = res.sums.total
				this.isLoading = false
			},
			// 重置搜索
			clearSearch() {
				this.searchFormData = {}
			},
		}
	}
</script>
<style lang="scss" scoped>
</style>
